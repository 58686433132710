import { Link } from 'gatsby'
import React from 'react'
import styles from './header.module.css'
// import TwitterIcon from './icon/twitter'

const Header = ({ onHideNav, onShowNav, showNav, siteTitle }) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <div className={styles.branding}>
        <Link to='/'>Jamie White</Link>
      </div>
    </div>
  </div>
)

export default Header
