import React, { useMemo } from 'react'
import Header from './header'
import '../styles/layout.css'
import styles from './layout.module.css'
import commaNumber from 'comma-number';
import Icon from './icon'
import { cn } from '../lib/helpers'

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle }) => {

  const days = useMemo(() => {
    const oneDay = 24 * 60 * 60 * 1000;
    const since = new Date('1991-01-01');
    const until = new Date();
    return commaNumber(Math.round(Math.abs((since - until) / oneDay)));
  });

  return (
    <>
      <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
      <div className={styles.content}>{children}</div>
      <footer className={styles.footer}>
        <div className={styles.footerWrapper}>
          <div className={styles.siteInfo}>
            {/* <p>© {new Date().getFullYear()} Jamie White</p> */}
            <nav className={cn(styles.nav, showNav && styles.showNav)}>
              {/* <ul> */}
              {/* <li> */}
              <a href='https://twitter.com/jamiemarkwhite' title="Twitter">< Icon symbol='twitter' /></a>
              <a href='https://www.linkedin.com/in/jamiemarkwhite' title="LinkedIn">< Icon symbol='linkedin' /></a>
              <a href='https://jamie-white.com' title="Blog">< Icon symbol='blog' /></a>
              {/* </li> */}
              {/* </ul> */}
            </nav>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Layout
