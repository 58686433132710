import { Link } from 'gatsby'
import React from 'react'
// import ProjectPreview from './project-preview'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import styles from './project-preview-grid.module.css'

function ProjectHeader(props) {
    const { mainImage } = props;
    return (
        <div className={styles.root}>
            {mainImage && mainImage.asset && (
                <div className={styles.mainImage}>
                    <img
                        src={imageUrlFor(buildImageObj(mainImage))
                            // .width(1200)
                            .maxWidth(900)
                            .height(Math.floor((9 / 16) * 1200))
                            .fit('crop')
                            .url()}
                        alt={mainImage.alt}
                    />
                </div>
            )}
            {/* {props.title && <h2 className={styles.headline}>{props.title}</h2>}
            <ul className={styles.grid}>
                {props.nodes &&
                    props.nodes.map(node => (
                        <li key={node.id}>
                            <ProjectPreview {...node} />
                        </li>
                    ))}
            </ul>
            {props.browseMoreHref && (
                <div className={styles.browseMoreNav}>
                    <Link to={props.browseMoreHref}>Browse more</Link>
                </div>
            )} */}
        </div>
    )
}

ProjectHeader.defaultProps = {
    mainImage: null
}
//     title: '',
//     nodes: [],
//     browseMoreHref: ''
// }

export default ProjectHeader
