import { format, distanceInWords, differenceInDays } from 'date-fns'
import React from 'react'
import { Link } from 'gatsby'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockContent from './block-content'
import Container from './container'
import RoleList from './role-list'

import styles from './project.module.css'
import ProjectHeader from './project-header'

import ReactPlayer from 'react-player'

function Project(props) {
  const { _rawBody, title, categories, mainImage, members, publishedAt, relatedProjects, vimeoVideos } = props
  return (
    <article className={styles.root}>
      <h1 className={styles.title}>{title}</h1>
      {/* <ProjectHeader mainImage={mainImage} /> */}
      {props.mainImage && mainImage.asset && (
        <div className={styles.mainImage}>
          <img
            src={imageUrlFor(buildImageObj(mainImage)).url()}
            alt={mainImage.alt}
          />
        </div>
      )}
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            {_rawBody && <BlockContent blocks={_rawBody || []} />}
            {
              vimeoVideos && vimeoVideos.map(({ vimeoId }) => {
                return (
                  <div key={`video-${vimeoId}`} className={styles.video}>
                    <div style={{
                      display: 'block',
                      position: 'relative',
                      width: '100%',
                      height: '0',
                      paddingBottom: '56.25%'
                    }}>
                      <ReactPlayer
                        url={`https://vimeo.com/${vimeoId}`}
                        // playing
                        controls
                        // autoplay
                        // muted={true}
                        width={'100%'}
                        height={'100%'}
                        style={{
                          display: 'block',
                          position: 'absolute',
                          top: '0',
                          left: '0'
                        }}
                      // playsinline 
                      />
                    </div>
                    <p className={styles.videoTitle}>{title}</p>
                  </div>
                )
              })
            }
          </div>
          {/* <aside className={styles.metaContent}>
            {publishedAt && (
              <div className={styles.publishedAt}>
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? distanceInWords(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), 'MMMM Do YYYY')}
              </div>
            )}
            {relatedProjects && relatedProjects.length > 0 && (
              <div className={styles.relatedProjects}>
                <h3 className={styles.relatedProjectsHeadline}>Related projects</h3>
                <ul>
                  {relatedProjects.map(project => (
                    <li key={`related_${project._id}`}>
                      {project.slug ? (
                        <Link to={`/project/${project.slug.current}`}>{project.title}</Link>
                      ) : (
                        <span>{project.title}</span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </aside> */}
        </div>
      </Container>
    </article>
  )
}

export default Project
