import React from 'react'
import HamburgerIcon from './hamburger'
import TwitterIcon from './twitter';
import LinkedInIcon from './linkedin';
import BlogIcon from './blog';

function Icon (props) {
  switch (props.symbol) {
    case 'hamburger':
      return <HamburgerIcon />
    case 'twitter':
      return <TwitterIcon />
    case 'linkedin':
      return <LinkedInIcon/>
    case 'blog':
      return <BlogIcon/>
    default:
      return <span>Unknown icon: {props.symbol}</span>
  }
}

export default Icon
